import React, { useState } from "react";

import { useLogin, useTranslate } from "@hooks";
export interface ILoginForm {
    username: string;
    password: string;
}

/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/api-references/components/refine-config#loginpage} for more details.
 */
export const LoginPage: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const translate = useTranslate();

    const { mutate: login } = useLogin<ILoginForm>();

    return (
        <>
            <h1>Login</h1>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    login({ username, password });
                }}
            >
                <table>
                    <tbody>
                        <tr>
                            <td>
                                {translate("pages.login.username", "username")}:
                            </td>
                            <td>
                                <input
                                    type="text"
                                    size={20}
                                    autoCorrect="off"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                    autoFocus
                                    required
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {translate("pages.login.password", "password")}:
                            </td>
                            <td>
                                <input
                                    type="password"
                                    required
                                    size={20}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <input type="submit" value="login" />
            </form>
        </>
    );
};
